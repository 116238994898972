<template>
  <DashboardContainer>
    <Spinner v-if="loading" class="mt-24" />
    <template v-else>
      <!-- Lead list (smallest breakpoint only) -->
      <div class="sm:hidden">
        <template v-if="isGivarooStaff">
          <div class="flex items-center mb-4">
            <input
              name="start"
              type="date"
              class="input--text--small placeholder:text-gray-400"
              placeholder="Select date start"
              @input="getDateRangeOption1($event.target.value)"
            />
            <p class="mx-2 text-sm text-gray-500">to</p>
            <input
              name="start"
              type="date"
              class="input--text--small"
              placeholder="Select date end"
              @input="getDateRangeOption2($event.target.value)"
            />
          </div>
          <div class="flex flex-col">
            <div class="flex mb-2">
              <select
                class="input--text--small mr-2"
                @change="filterByCampaign($event.target.value)"
              >
                <option :selected="!selectedCampaignFilter.id" :value="''">All Campaigns</option>
                <option
                  v-for="campaign in campaignFilters"
                  :key="campaignFilters.indexOf(campaign)"
                  :value="campaign.id"
                >
                  {{ campaign.location ? campaign.name + ' ' + campaign.location : campaign.name }}
                </option>
              </select>
              <select class="input--text--small" @change="filterByFundraiser($event.target.value)">
                <option :selected="!selectedFundraiserFilter.id" :value="''">
                  All Fundraisers
                </option>
                <option
                  v-for="fundraiser in fundraiserFilters"
                  :key="fundraiserFilters.indexOf(fundraiser)"
                  :value="fundraiser.id"
                >
                  {{ fundraiser.fullName }}
                </option>
              </select>
            </div>
          </div>
          <button
            @click="exportLeads"
            v-if="isGivarooStaff"
            class="btn--table--export max-w-fit mt-8 mb-2"
          >
            Export Leads
          </button>
        </template>
        <Spinner v-if="updatingTable" class="mt-8" />
        <template v-else>
          <ul
            v-if="leads.list?.length"
            role="list"
            class="mt-2 rounded-lg divide-y divide-gray-200 overflow-hidden shadow sm:hidden"
          >
            <li v-for="(lead, key) in leads.list" :key="key" class="block bg-white px-4 py-4">
              <div class="flex items-center space-x-4">
                <div class="flex flex-1 space-x-2 truncate">
                  <div class="flex w-full text-sm text-gray-500">
                    <div class="truncate">
                      <strong class="truncate block text-black pr-4"
                        >{{ lead.firstName }} {{ lead.lastName }}
                      </strong>
                      <em class="truncate">{{
                        lead.campaign.location
                          ? lead.campaign.name + ' ' + lead.campaign.location
                          : lead.campaign.name
                      }}</em>
                    </div>
                    <div class="ml-auto text-right flex flex-col justify-between">
                      <span class="block">{{ formatDateString(lead.datetimeCreated) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <p v-else class="mt-2 p-4 rounded-lg divide-y divide-gray-200 bg-white overflow-hidden">
            No results found.
          </p>
        </template>
      </div>

      <!-- Lead list (small breakpoint and up) -->
      <div class="hidden sm:block">
        <template v-if="isGivarooStaff">
          <div class="grid grid-cols-4 gap-2">
            <div class="flex items-center col-span-2">
              <input
                name="start"
                type="date"
                class="input--text--small"
                placeholder="Select date start"
                @input="getDateRangeOption1($event.target.value)"
              />
              <p class="mx-2 text-sm text-gray-500">to</p>
              <input
                name="start"
                type="date"
                class="input--text--small"
                placeholder="Select date end"
                @input="getDateRangeOption2($event.target.value)"
              />
            </div>
            <select class="input--text--small" @change="filterByCampaign($event.target.value)">
              <option :selected="!selectedCampaignFilter.id" :value="''">All Campaigns</option>
              <option
                v-for="campaign in campaignFilters"
                :key="campaignFilters.indexOf(campaign)"
                :value="campaign.id"
              >
                {{ campaign.location ? campaign.name + ' ' + campaign.location : campaign.name }}
              </option>
            </select>
            <select class="input--text--small" @change="filterByFundraiser($event.target.value)">
              <option :selected="!selectedFundraiserFilter.id" value="">All Fundraisers</option>
              <option
                v-for="fundraiser in fundraiserFilters"
                :key="fundraiserFilters.indexOf(fundraiser)"
                :value="fundraiser.id"
              >
                {{ fundraiser.fullName }}
              </option>
            </select>
          </div>
          <div class="my-2 grid grid-cols-4 gap-2 mb-8">
            <div
              class="input--filter flex justify-between mr-2 hover:cursor-pointer col-start-3 cold-end-4"
              :class="{ invisible: !selectedCampaignFilter.name }"
              @click="clearCampaignFilter()"
            >
              <p class="text-sm font-bold">
                {{
                  selectedCampaignFilter.location
                    ? selectedCampaignFilter.name + ' ' + selectedCampaignFilter.location
                    : selectedCampaignFilter.name
                }}
              </p>
              <XMarkIcon class="h-5" />
            </div>
            <div
              class="input--filter flex justify-between mr-2 hover:cursor-pointer col-start-4"
              :class="{ invisible: !selectedFundraiserFilter.name }"
              @click="clearFundraiserFilter()"
            >
              <p class="text-sm font-bold">{{ selectedFundraiserFilter.name }}</p>
              <XMarkIcon class="h-5" />
            </div>
          </div>
          <button
            @click="exportLeads"
            v-if="isGivarooStaff"
            class="btn--table--export max-w-fit mt-8 mb-2"
          >
            Export Leads
          </button>
        </template>
        <div class="mt-2 flex flex-col">
          <Spinner v-if="updatingTable" />
          <div
            v-else
            class="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr class="bg-gray-50 rounded-tl-lg rounded-tr-lg">
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Name
                  </th>
                  <th
                    v-if="isGivarooStaff"
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Campaign
                  </th>
                  <th
                    v-if="isGivarooStaff"
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Fundraiser
                  </th>
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Date
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <template v-if="leads.list?.length">
                  <tr v-for="(lead, key) in leads.list" :key="key">
                    <td class="whitespace-nowrap py-4 px-6 text-sm text-black">
                      {{ lead.firstName }} {{ lead.lastName }}
                    </td>
                    <td
                      v-if="isGivarooStaff"
                      class="whitespace-nowrap py-4 px-6 text-sm text-gray-500"
                    >
                      {{ lead.email }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                      {{
                        lead.campaign.location
                          ? lead.campaign.name + ' ' + lead.campaign.location
                          : lead.campaign.name
                      }}
                    </td>
                    <td
                      v-if="isGivarooStaff"
                      class="whitespace-nowrap py-4 px-6 text-sm text-gray-500"
                    >
                      {{ lead.fundraiser.fullName }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                      {{ formatDateString(lead.datetimeCreated) }}
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="5" class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                    No results found.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </DashboardContainer>
</template>

<script>
import { computed, onBeforeMount, reactive, ref, triggerRef } from 'vue'
import { useStore } from 'vuex'
import DashboardContainer from '@/components/DashboardContainer.vue'
import Spinner from '@/components/Spinner'
import { leadFunctions, LeadApi } from '@/services/leads/'
import { XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'LeadList',
  components: {
    DashboardContainer,
    Spinner,
    XMarkIcon,
  },
  setup() {
    const store = useStore()
    const isGivarooStaff = ref(store.getters.isGivarooStaff)
    const { leadCollection, leadFilters } = leadFunctions()
    const leads = ref(leadCollection)
    const leadRefs = ref([])
    const loading = ref(false)
    const updatingTable = ref(false)

    const campaignFilters = computed(() =>
      leadRefs.value ? getUniqueObjectsArray(leadRefs.value, 'campaign') : null,
    )
    const fundraiserFilters = computed(() =>
      leadRefs.value ? getUniqueObjectsArray(leadRefs.value, 'fundraiser') : null,
    )

    const selectedCampaignFilter = reactive({
      location: '',
      name: '',
      id: '',
    })
    const selectedFundraiserFilter = reactive({
      name: '',
      id: '',
    })
    const selectedDateRange = reactive({
      date1: '',
      date2: '',
    })

    const getUniqueObjectsArray = (arr, key, value = false) => {
      const filteredArr = arr.filter((obj) => obj[`${key}`] != null)

      if (value) {
        return Object.values(
          filteredArr
            .map((obj) => obj[`${key}`])
            .reduce((acc, obj) => ({ ...acc, [obj.value]: obj.value }), {}),
        )
      } else {
        return Object.values(
          filteredArr
            .map((obj) => obj[`${key}`])
            .reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {}),
        )
      }
    }

    onBeforeMount(async () => {
      loading.value = true
      await leads.value.refresh().catch((error) => {
        alert(error)
      })
      triggerRef(leads)
      leadRefs.value = leads.value.list
      loading.value = false
    })

    const getLeads = async () => {
      updatingTable.value = true
      setFilters()

      await leads.value.refresh().catch((error) => {
        alert(error)
      })
      triggerRef(leads)
      updatingTable.value = false
    }

    const formatDate = (date) => {
      return new Date(date)
    }

    const formatDateString = (date) => {
      return formatDate(date).toLocaleDateString()
    }

    const formatDateQueryParam = (date) => {
      return formatDate(date).toISOString().split('T')[0]
    }

    const filterByCampaign = (option) => {
      if (option) {
        let selectedCampaign = campaignFilters.value.find((campaign) => campaign.id == option)
        selectedCampaignFilter.name = selectedCampaign.name
        selectedCampaignFilter.location = selectedCampaign.location
        selectedCampaignFilter.id = selectedCampaign.id
        getLeads()
      } else {
        clearCampaignFilter()
      }
    }

    const filterByFundraiser = (option) => {
      if (option) {
        let selectedFundraiser = fundraiserFilters.value.find(
          (fundraiser) => fundraiser.id == option,
        )
        selectedFundraiserFilter.name = selectedFundraiser.fullName
        selectedFundraiserFilter.id = selectedFundraiser.id
        getLeads()
      } else {
        clearFundraiserFilter()
      }
    }

    const filterByDate = () => {
      if (
        (selectedDateRange.date1 && selectedDateRange.date2) ||
        (!selectedDateRange.date1 && !selectedDateRange.date)
      ) {
        getLeads()
      }
    }

    const getDateRangeOption1 = (date) => {
      if (date) {
        // eslint-disable-next-line
        date = date.replace(/-/g, '\/')
        selectedDateRange.date1 = formatDateQueryParam(date)
      } else {
        selectedDateRange.date1 = ''
      }
      filterByDate()
    }

    const getDateRangeOption2 = (date) => {
      if (date) {
        // eslint-disable-next-line
        date = date.replace(/-/g, '\/')
        selectedDateRange.date2 = formatDateQueryParam(date)
      } else {
        selectedDateRange.date2 = ''
      }
      filterByDate()
    }

    const getStartAndEndDates = () => {
      let date1 = selectedDateRange.date1
      let date2 = selectedDateRange.date2

      if (date1 && date2) {
        if (date1 >= date2) {
          leadFilters.dateRangeStart = date2
          leadFilters.dateRangeEnd = date1
        } else if (date1 <= date2) {
          leadFilters.dateRangeStart = date1
          leadFilters.dateRangeEnd = date2
        }
      } else if (!date1 || !date2) {
        leadFilters.dateRangeStart = leadFilters.dateRangeEnd = ''
      }
    }

    const clearCampaignFilter = () => {
      Object.keys(selectedCampaignFilter).map((key) => (selectedCampaignFilter[key] = ''))
      getLeads()
    }

    const clearFundraiserFilter = () => {
      Object.keys(selectedFundraiserFilter).map((key) => (selectedFundraiserFilter[key] = ''))
      getLeads()
    }

    const setFilters = () => {
      leadFilters.campaign = selectedCampaignFilter.id
      leadFilters.fundraiser = selectedFundraiserFilter.id
      getStartAndEndDates()
    }

    const exportLeads = () => {
      setFilters()
      const { ordering, ...filters } = leadFilters
      LeadApi.csc.generateExport({ ...filters })
    }

    return {
      isGivarooStaff,
      leads,
      leadFilters,
      loading,
      updatingTable,
      campaignFilters,
      fundraiserFilters,
      selectedCampaignFilter,
      selectedFundraiserFilter,
      selectedDateRange,
      filterByCampaign,
      filterByFundraiser,
      formatDateString,
      getDateRangeOption1,
      getDateRangeOption2,
      clearCampaignFilter,
      clearFundraiserFilter,
      exportLeads,
    }
  },
}
</script>
